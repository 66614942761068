import {
  type SuggestionsQuery,
  type SuggestionsQueryVariables,
} from '~/v1/_types/SuggestionsQuery';
import { type SearchSuggestionResponse } from '~/v1/components/search/search.interface';
import { client } from '~/v1/graphql/client';
import { SUGGESTIONS_QUERY } from '~/v1/graphql/queries/suggestions';

export const getSuggestions = async (
  variables: SuggestionsQueryVariables,
): Promise<SearchSuggestionResponse | null> => {
  const { data } = await client.query<SuggestionsQuery>({
    query: SUGGESTIONS_QUERY,
    variables,
  });

  return data.globalAutocomplete
    ? {
        primarySuggestions: data.globalAutocomplete?.primarySuggestions || [],
        secondarySuggestions: data.globalAutocomplete?.secondarySuggestions || [],
      }
    : null;
};
