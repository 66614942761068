import { Event, trackEvent } from '~/ui/components/analytics';

import { type SocialIcon } from './social.interface';
import styles from './social.module.scss';
import { Icon } from '../icons/icon';
import { Link } from '../link/link';

interface SocialMediaProps {
  icons: SocialIcon[];
}

export const SocialMedia: React.FC<SocialMediaProps> = ({ icons }) => {
  return (
    <>
      {icons.map((iconLink, index) => (
        <Link
          className={styles.iconLink}
          key={`${iconLink.link}-${index}`}
          href={iconLink.link}
          ariaLabel={iconLink.ariaLabel}
          onClick={() =>
            trackEvent(Event.SOCIAL_MEDIA_CLICK, {
              socialType: iconLink.type,
              socialLink: iconLink.link,
            })
          }
        >
          <Icon type={iconLink.type} className={styles.icon} />
        </Link>
      ))}
    </>
  );
};
