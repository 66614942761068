'use client';

import { forwardRef } from 'react';

import { Slot } from '@radix-ui/react-slot';
import cx from 'classnames';

import styles from './fullBleedCta.module.scss';
import { FullBleedCTAScope, useIsInFullBleedCTA } from './fullBleedCta.utils';

export const FULL_BLEED_CTA_TRIGGER_PROP = '__fullBleedCTATrigger';

export type FullBleedCTAProps<T extends React.ElementType = 'article'> = {
  as?: T;
  label: string;
  children: React.ReactNode;
} & React.ComponentPropsWithRef<T>;

/**
 * FullBleedCTA, combined with {@link FullBleedCTATrigger}, creates an element
 * that is completely clickable while being semantically correct. e.x. a card
 *
 * @example
 * <FullBleedCTA label="Defining Public Knowledge with Program Officer Patricia Hswe">
 *   <h2>Defining Public Knowledge with Program Officer Patricia Hswe</h2>
 *   <FullBleedCTATrigger>
 *     <Link href="/article/defining-public-knowledge-with-program-officer-patricia-hswe">
 *       Read the article
 *     </Link>
 *   </FullBleedCTATrigger>
 * </FullBleedCTA>
 */
export const FullBleedCTA = forwardRef(FullBleedCTAImpl);
function FullBleedCTAImpl<T extends React.ElementType = 'article'>(
  { as: Component = 'article', label, children, className, ...props }: FullBleedCTAProps<T>,
  ref: React.ForwardedRef<React.ComponentRef<T>>,
): JSX.Element {
  return (
    <FullBleedCTAScope>
      <Component ref={ref} aria-label={label} className={cx(styles.root, className)} {...props}>
        {children}
      </Component>
    </FullBleedCTAScope>
  );
}

export type FullBleedCTATriggerProps = {
  children: React.ReactNode;
};

/**
 * FullBleedCTATrigger wraps the clickable element within a FullBleedCTA. The
 * clickable area of this element will cover the entire FullBleedCTA.
 *
 * {@see FullBleedCTA}
 */
export function FullBleedCTATrigger({ children }: FullBleedCTATriggerProps): JSX.Element {
  const isInFullBleedCTA = useIsInFullBleedCTA();
  if (!isInFullBleedCTA) {
    throw new Error('FullBleedCTATrigger must be a child of FullBleedCTA');
  }

  return (
    <Slot className={styles.trigger} {...{ [FULL_BLEED_CTA_TRIGGER_PROP]: true }}>
      {children}
    </Slot>
  );
}
