'use client';

import { usePathname } from 'next/navigation';
import { type MutableRefObject, useEffect, useState } from 'react';

import Lottie from 'react-lottie-player';

import { useOnScreen } from '~/v1/hooks/useOnScreen';

import styles from './animation.module.scss';
import lottieJson from './footerLottie.json';

export function FooterLogo() {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const pathname = usePathname();
  const { ref, inView } = useOnScreen();

  const onComplete = () => {
    setShouldAnimate(false);
  };

  useEffect(() => {
    setShouldAnimate(true);
  }, [pathname]);

  return (
    <div ref={ref as MutableRefObject<HTMLDivElement | null>} className={styles.logo}>
      <Lottie
        key={pathname}
        loop={false}
        animationData={lottieJson}
        play={inView && shouldAnimate}
        onComplete={onComplete}
      />
    </div>
  );
}
