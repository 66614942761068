import { Column, Grid } from '~/ui/components/grid';
import gridStyles from '~/ui/styles/grid.module.scss';
import { PrimaryNavigationPlaceholder } from '~/v1/components/navigation/placeholder/placeholder';

import styles from './guidelines.module.scss';

const columnCount = parseInt(gridStyles.columnsLg, 10);

export function GridGuidelines() {
  return (
    <div className={styles.gridWrapper}>
      <PrimaryNavigationPlaceholder />
      <Grid>
        {Array.from({ length: columnCount }).map((_, index) => (
          <Column key={index} className={styles.column} sm={1} md={1} lg={1} />
        ))}
      </Grid>
    </div>
  );
}
