import { createContext, useContext } from 'react';

import { FULL_BLEED_CTA_TRIGGER_PROP } from './fullBleedCta';

export const FullBleedCTAScopeContext = createContext<boolean>(false);

export function FullBleedCTAScope({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <FullBleedCTAScopeContext.Provider value={true}>{children}</FullBleedCTAScopeContext.Provider>
  );
}

export function useIsInFullBleedCTA(): boolean {
  return useContext(FullBleedCTAScopeContext);
}

export function useIsFullBleedCTATrigger(props: unknown): boolean {
  return (
    typeof props === 'object' &&
    props !== null &&
    FULL_BLEED_CTA_TRIGGER_PROP in props &&
    props[FULL_BLEED_CTA_TRIGGER_PROP] === true
  );
}
