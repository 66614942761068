import {
  type ChangeEvent,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSearchContext } from '../search.context';

interface UseEntryHook {
  ref: MutableRefObject<HTMLInputElement | null>;
  isNewSearch: boolean;
  isOverflowing: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onClear: () => void;
}

export const useEntryHook = (): UseEntryHook => {
  const { isGlobal, onQueryChange, onSearchClear, onSearchSubmit, query } = useSearchContext();
  const [isNewSearch, setIsNewSearch] = useState(!!isGlobal);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!isNewSearch) {
      setIsNewSearch(true);
    }

    onQueryChange(value);
  };

  const onFocus = () => {
    if (query !== undefined && query !== null) {
      ref.current?.setSelectionRange(query.length, query.length);
    }
    ref.current?.focus();
  };

  const onClear = () => {
    ref.current?.focus();
    setIsNewSearch(true);
    onSearchClear();
  };

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        setIsNewSearch(false);
        onSearchSubmit();
      }
    },
    [onSearchSubmit],
  );

  useEffect(() => {
    const { current: input } = ref;

    if (input) {
      input.addEventListener('keyup', onKeyUp);
      // The +1 is to address an odd scenario where the empty input will return
      // a `scrollWidth` value as 1px larger than `clientWidth` if the screen size is exactly 1280px
      setIsOverflowing(input.scrollWidth > input.clientWidth + 1);
    }

    return () => {
      input?.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return {
    ref,
    isNewSearch,
    isOverflowing,
    onChange,
    onFocus,
    onClear,
  };
};
