import { TEST_ID } from '~/v1/constants/testId';

import styles from './suggestion.module.scss';

export const SearchSuggestionSkeleton: React.FC = () => {
  return (
    <div className={styles.skeleton} aria-hidden data-test-id={TEST_ID.SKELETON}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
