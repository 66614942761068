import { type MutableRefObject, useEffect, useRef, useState } from 'react';

interface UseOnScreen {
  ref: MutableRefObject<HTMLElement | null>;
  inView: boolean;
}

/** @deprecated use {@link import('framer-motion').useInView} */
export const useOnScreen = (): UseOnScreen => {
  const [inView, setInView] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setInView(entry.isIntersecting));

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return {
    ref,
    inView,
  };
};
