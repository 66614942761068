import {
  Breakpoint,
  Columns,
  MAX_GRID_WIDTH,
  Margin,
  MediaQuery,
  MinWidth,
} from '~/ui/styles/grid';

import { type ImageColSpan } from './image.interface';

/**
 * Defines the maximum width that a column can be for each breakpoint. This is used to calculate
 * the width of an image based on the number of columns it spans.
 */
const MaxColumnWidth: Record<Breakpoint, number> = {
  [Breakpoint.SM]:
    (MinWidth[Breakpoint.MD] - 1 - Margin[Breakpoint.SM] * 2) / Columns[Breakpoint.SM],
  [Breakpoint.MD]:
    (MinWidth[Breakpoint.LG] - 1 - Margin[Breakpoint.MD] * 2) / Columns[Breakpoint.MD],
  [Breakpoint.LG]: MAX_GRID_WIDTH / Columns[Breakpoint.LG],
} as const;

// We need values for all breakpoints (SM to LG). If the user has only passed
// in a subset we will fill out the rest here (inheriting from lower breakpoint).
function getValuesForAllBreakpoints(breakpointValues: ImageColSpan): ImageColSpan {
  const allBreakpointValues: ImageColSpan = { ...breakpointValues };

  Object.values(Breakpoint).forEach((bk, idx, arr) => {
    // Recursively finds a previous breakpoint with a value.
    const assignValToPrevBk = (currentIdx: number) => {
      const prevBk = arr[currentIdx - 1];

      if (prevBk) {
        allBreakpointValues[bk] = allBreakpointValues[prevBk];
      } else {
        assignValToPrevBk(currentIdx - 1);
      }
    };

    if (!allBreakpointValues[bk] && idx > 0) {
      assignValToPrevBk(idx);
    }
  });

  return allBreakpointValues;
}

// Calculate the width based on number of columns and breakpoint.
// Note: this will always use the highest px value for the breakpoint for the calc.
function getSpanWidth(breakpoint: Breakpoint, colSpan: number): number {
  return Math.ceil(MaxColumnWidth[breakpoint] * colSpan);
}

// Returns a "sizes" string based on colSpan.
export function getSizesFromSpan(colSpan: ImageColSpan): string {
  if (Object.keys(colSpan).length === 0) {
    return '';
  }

  const allBreakpointSpans = getValuesForAllBreakpoints(colSpan);
  const sizes = Object.values(Breakpoint).map(value => {
    const colSpan = allBreakpointSpans[value] || 0;
    return `${MediaQuery[value]} ${getSpanWidth(value, colSpan)}px`;
  });

  return sizes.join(', ');
}
