import cx from 'classnames';
import { useReducedMotion } from 'framer-motion';

import { Event, trackEvent } from '~/ui/components/analytics';
import { type Footer as FooterInterface } from '~/v1/_types/Footer';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Link } from '~/v1/components/link/link';
import { Newsletter } from '~/v1/components/newsletter/newsletter';
import { NewsLetterId } from '~/v1/components/newsletter/newsletter.interface';
import { mapQuickLinks } from '~/v1/components/quickLinks/quickLinks.utils';
import { SocialMedia } from '~/v1/components/social/social';
import { mapSocialIcons } from '~/v1/components/social/social.utils';
import { Route } from '~/v1/constants/route';
import { TEST_ID } from '~/v1/constants/testId';
import { useHasCursor } from '~/v1/hooks/useHasCursor';
import { AnimatedIdeas, StaticIdeas } from '~/v1/modules/footer/ideas';

import { FooterLogo } from './animation/animation';
import styles from './footer.module.scss';

interface FooterProps {
  footer: FooterInterface;
  isCondensed?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ footer, isCondensed = false }) => {
  const {
    ideas,
    threadsLink,
    facebookLink,
    linkedInLink,
    instagramLink,
    youtubeLink,
    footerLinks,
    newsletter,
  } = footer;
  const prefersReducedMotion = useReducedMotion();
  const hasCursor = useHasCursor();

  return (
    <footer className={styles.root} data-test-id={TEST_ID.FOOTER.WRAP}>
      {!isCondensed && (
        <nav>
          <div className={cx('bodyLargeTypography', styles.title)}>Explore Ideas</div>
          {hasCursor && !prefersReducedMotion ? (
            <AnimatedIdeas ideas={ideas} />
          ) : (
            <StaticIdeas ideas={ideas} />
          )}
        </nav>
      )}

      <div className={styles.newsletterWrapper} data-test-id={TEST_ID.FOOTER.NEWSLETTER}>
        <Newsletter
          id={NewsLetterId.FOOTER}
          title={newsletter.description}
          disclaimer={newsletter.disclaimer}
          isInFooter
          className={styles.newsletter}
        />
      </div>

      <FooterLogo />

      <nav className={styles.linksWrapper} data-test-id={TEST_ID.FOOTER.LINKS}>
        <ul className={styles.footerLinks}>
          {mapQuickLinks(footerLinks).map((footerLink, index) => (
            <li key={`${index}-${footerLink.link}`}>
              <Link
                href={{
                  pathname: footerLink.link,
                  query: { follow: false },
                }}
                as={footerLink.link}
                onClick={() =>
                  trackEvent(Event.NAVIGATION_FOOTER, {
                    navigationItem: footerLink.label,
                    navigationLink: footerLink.link,
                  })
                }
              >
                {footerLink.label}
              </Link>
            </li>
          ))}
          <li>
            <Link
              href={{
                pathname: Route.NEWSLETTER,
                query: { follow: false },
              }}
              as={Route.NEWSLETTER}
              onClick={() =>
                trackEvent(Event.NAVIGATION_FOOTER, {
                  navigationItem: 'Newsletter',
                  navigationLink: Route.NEWSLETTER,
                })
              }
            >
              Newsletter
            </Link>
          </li>
          <li>
            <Link
              href={{
                pathname: Route.ANNUAL_REPORT,
                query: { follow: false },
              }}
              as={Route.ANNUAL_REPORT}
              onClick={() =>
                trackEvent(Event.NAVIGATION_FOOTER, {
                  navigationItem: 'Annual Report',
                  navigationLink: Route.ANNUAL_REPORT,
                })
              }
            >
              Annual Report
            </Link>
          </li>
        </ul>
        <div className={styles.line} aria-hidden>
          <Icon type={IconType.Line} />
        </div>

        <div className={styles.iconsWrapper}>
          <div className="captionTypography">
            © {new Date().getFullYear()} The Andrew W. Mellon Foundation
          </div>
          <div className={styles.icons}>
            <SocialMedia
              {...mapSocialIcons({
                threadsLink,
                facebookLink,
                linkedInLink,
                instagramLink,
                youtubeLink,
              })}
            />
          </div>
        </div>
      </nav>
    </footer>
  );
};
