export interface CardEvent {
  cardModule?: AnalyticsModule;
  cardType: AnalyticsCard;
  cardContentType?: AnalyticsContent;
  cardTitle: string;
  cardCTA: string;
}

export interface SearchEvent {
  searchTerm?: string;
  searchSuggestion?: string;
  searchResult?: string;
}

export interface NavigationEvent {
  navigationCategory?: string;
  navigationItem?: string;
  navigationLink?: string;
}

export interface SocialsEvent {
  socialType: string;
  socialLink?: string;
}

export interface SpotlightEvent {
  spotlightType: AnalyticsSpotlight;
  spotlightLink?: string;
  spotlightTitle: string;
}

export interface QuicklinkEvent {
  quicklinkLink: string;
  quicklinkCTA: string;
}

export interface GrantAccordionEvent {
  accordionTitle: string;
  accordionCTA: string;
  accordionLink: string;
}

export type GrantInsightEvent = {
  insightTitle: string;
  insightLink: string;
};

export interface CallToActionEvent {
  callToActionCTA: string;
  callToActionLink: string;
}

export interface CallForConceptsEvent {
  callForConceptsCTA: string;
  callForConceptsLink: string;
}

export interface QuoteEvent {
  quoteCopy: string;
  quoteLink?: string | null;
}

export type FilterEvent = Record<string, string[]>;

export enum Event {
  CARD_CLICK = 'card_click',
  NEWSLETTER_FOCUS = 'newsletter_focus',
  NEWSLETTER_SUBMIT = 'newsletter_subscribe',
  SEARCH_SUBMIT = 'global_search_submit',
  SEARCH_SUGGESTION_CLICK = 'global_search_suggestion_click',
  SEARCH_RESULT_CLICK = 'global_search_result_click',
  DATABASE_SEARCH_SUBMIT = 'database_search_submit',
  DATABASE_SEARCH_SUGGESTION_CLICK = 'database_search_suggestion_click',
  DATABASE_SEARCH_RESULT_CLICK = 'database_search_result_click',
  NAVIGATION_PRIMARY = 'primary_navigation_click',
  NAVIGATION_SECONDARY = 'secondary_navigation_click',
  NAVIGATION_FOOTER = 'footer_navigation_click',
  SOCIAL_MEDIA_CLICK = 'social_media_click',
  SOCIAL_MEDIA_SHARE = 'social_media_share',
  SPOTLIGHT_CLICK = 'spotlight_click',
  QUICKLINK_CLICK = 'quicklink_click',
  GRANT_ACCORDION_CLICK = 'grant_story_accordion_click',
  GRANT_INSIGHT_CLICK = 'grant_insight_click',
  CALL_TO_ACTION_CLICK = 'call_to_action_click',
  CALL_FOR_CONCEPTS_CLICK = 'call_for_concepts_click',
  QUOTE_AUDIO_PLAY = 'quote_audio_play',
  QUOTE_AUDIO_PAUSE = 'quote_audio_pause',
  FILTERS_APPLY = 'global_search_filter_apply',
  FILTERS_RESET = 'global_search_filter_reset',
  DATABASE_FILTERS_APPLY = 'database_search_filter_apply',
  DATABASE_FILTERS_RESET = 'database_search_filter_reset',
  NEWS_FILTERS_APPLY = 'news_filters_apply',
  NEWS_FILTERS_RESET = 'news_filters_reset',
  PEOPLE_FILTERS_APPLY = 'people_filters_apply',
  PEOPLE_FILTERS_RESET = 'people_filters_reset',
}

/**
 * This provides payload types for each event. If an event does not have a
 * payload, it is set to `never`.
 *
 * @see GlobalEventHandlersEventMap
 */
export interface EventMap {
  [Event.CARD_CLICK]: CardEvent;
  [Event.NEWSLETTER_FOCUS]: never;
  [Event.NEWSLETTER_SUBMIT]: never;
  [Event.SEARCH_SUBMIT]: SearchEvent;
  [Event.SEARCH_SUGGESTION_CLICK]: SearchEvent;
  [Event.SEARCH_RESULT_CLICK]: SearchEvent;
  [Event.DATABASE_SEARCH_SUBMIT]: SearchEvent;
  [Event.DATABASE_SEARCH_SUGGESTION_CLICK]: SearchEvent;
  [Event.DATABASE_SEARCH_RESULT_CLICK]: SearchEvent;
  [Event.NAVIGATION_PRIMARY]: NavigationEvent;
  [Event.NAVIGATION_SECONDARY]: NavigationEvent;
  [Event.NAVIGATION_FOOTER]: NavigationEvent;
  [Event.SOCIAL_MEDIA_CLICK]: SocialsEvent;
  [Event.SOCIAL_MEDIA_SHARE]: SocialsEvent;
  [Event.SPOTLIGHT_CLICK]: SpotlightEvent;
  [Event.QUICKLINK_CLICK]: QuicklinkEvent;
  [Event.GRANT_ACCORDION_CLICK]: GrantAccordionEvent;
  [Event.GRANT_INSIGHT_CLICK]: GrantInsightEvent;
  [Event.CALL_TO_ACTION_CLICK]: CallToActionEvent;
  [Event.CALL_FOR_CONCEPTS_CLICK]: CallForConceptsEvent;
  [Event.QUOTE_AUDIO_PLAY]: QuoteEvent;
  [Event.QUOTE_AUDIO_PAUSE]: QuoteEvent;
  [Event.FILTERS_APPLY]: FilterEvent;
  [Event.FILTERS_RESET]: never;
  [Event.DATABASE_FILTERS_APPLY]: FilterEvent;
  [Event.DATABASE_FILTERS_RESET]: never;
  [Event.NEWS_FILTERS_APPLY]: FilterEvent;
  [Event.NEWS_FILTERS_RESET]: never;
  [Event.PEOPLE_FILTERS_APPLY]: FilterEvent;
  [Event.PEOPLE_FILTERS_RESET]: never;
}

export enum AnalyticsModule {
  RELATED = 'Related module',
  RELATED_GRANTS = 'Related grants module',
  RELATED_REPORTS = 'Related reports module',
  UPCOMING_EVENTS = 'Upcoming events module',
  AT_GLANCE = 'At glance module',
  IMPACT_ARTICLES = 'Impact articles module',
  INITIATIVE_QUOTE = 'Initiative quote module',
  GRANTMAKING_AREAS = 'Grantmaking areas module',
  LEADERSHIP = 'Leadership module',
  STAFF = 'Staff module',
  NEWS = 'News module',
  FEATURED = 'Featured module',
  FEATURE_CALLOUT = 'Feature callout module',
  FEATURED_NEWS = 'Featured news module',
  FEATURED_EVENT = 'Featured event module',
}

export enum AnalyticsCard {
  ANNUAL_REPORT = 'Annual Report card',
  REPORT = 'Report card',
  IDEA = 'Idea card',
  ARTICLE = 'Article card',
  HIGHLIGHT = 'Highlight card',
  EVENT = 'Event card',
  VOICES = 'Voices card',
  GRANT = 'Grant card',
  NEWS = 'News card',
  LANDING = 'Landing card',
  LEADERSHIP = 'Leadership card',
  STAFF = 'Staff card',
  PROFILE = 'Profile card',
  EMPHASIZED = 'Emphasized card',
  MMUF = 'MMUF card',
  FEATURE = 'Feature card',
}

export enum AnalyticsSpotlight {
  SLIDESHOW = 'Slideshow spotlight',
  VIDEO = 'Video spotlight',
  HOMEPAGE = 'Homepage spotlight',
}

export enum AnalyticsContent {
  DEFAULT = 'Non-detected content',
  ARTICLE = 'Article content',
  GRANT_STORY = 'Grant story content',
  PAST_EVENT = 'Past event content',
  FUTURE_EVENT = 'Future event content',
  GRANT_DETAILS = 'Grant details content',
  ANNUAL_REPORT = 'Annual report content',
  LEADERSHIP = 'Leadership content',
  STAFF = 'Staff content',
  REPORT = 'Report content',
  MELLON_STAFF = 'Mellon staff content',
  EXTERNAL_CONTRIBUTOR = 'External contributor content',
  INTERNAL_NEWS = 'Internal news content',
  EXTERNAL_NEWS = 'Exetrnal news content',
  INTERNAL_PROFILE = 'Internal Mellon profile content',
  EXTERNAL_PROFILE = 'Contributor profile content',
}
