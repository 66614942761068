import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';

import { Event, trackEvent } from '~/ui/components/analytics';
import { useAsync } from '~/v1/hooks/useAsync';

import {
  type SearchContextType,
  SearchGtmType,
  type SearchQueryFn,
  type SearchSuggestionType,
} from './search.interface';
import { mapSuggestions } from './suggestions/suggestions.utils';

const SEARCH_DEBOUNCE = 800;
export const SEARCH_CHAR_LIMIT = 4;

interface UseSearchHook {
  api: SearchQueryFn;
  isGlobal: boolean;
  redirectTo: string;
  gtm: SearchGtmType;
  query?: string;
}

export const useSearchHook = ({
  api,
  isGlobal,
  redirectTo,
  gtm,
  query: inputQuery,
}: UseSearchHook): SearchContextType => {
  const { isLoading, async } = useAsync();
  const router = useRouter();
  const initialQuery = isGlobal ? '' : inputQuery ?? '';
  const [query, setQuery] = useState<string>(initialQuery);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [staticQuery, setStaticQuery] = useState(initialQuery);
  const [suggestions, setSuggestions] = useState<SearchSuggestionType[] | null>(null);
  const suggestionsQuery = useRef('');
  const submitEvent =
    gtm === SearchGtmType.GLOBAL ? Event.SEARCH_SUBMIT : Event.DATABASE_SEARCH_SUBMIT;

  useEffect(() => {
    setIsRedirecting(false);

    if (!isGlobal) {
      setQuery(inputQuery ?? '');
      setSuggestions(null);
      setStaticQuery(initialQuery);
    }
  }, [inputQuery, isGlobal, initialQuery]);

  const doQuery = async (value: string) => {
    const data = await api({ term: value });

    suggestionsQuery.current = value;

    setSuggestions(mapSuggestions(data));

    return Promise.resolve();
  };

  const debouncedQuery = useRef(debounce(async(doQuery), SEARCH_DEBOUNCE));

  const onQueryChange = (value: string) => {
    setQuery(value);

    if (!value.length || value.length < SEARCH_CHAR_LIMIT) {
      debouncedQuery.current.cancel();

      setSuggestions(null);
    }

    return value.length >= SEARCH_CHAR_LIMIT && !isRedirecting && debouncedQuery.current(value);
  };

  const onSearchSubmit = () => {
    if (!isRedirecting) {
      setIsRedirecting(true);

      debouncedQuery.current.cancel();

      trackEvent(submitEvent, { searchTerm: query });

      router.push(`${redirectTo}/${encodeURIComponent(query)}?follow=false`);
    }
  };

  const onSearchClear = () => {
    setQuery('');
    setSuggestions(null);
  };

  return {
    gtm,
    query,
    staticQuery,
    suggestionsQuery: suggestionsQuery.current,
    suggestions,
    isLoading,
    isGlobal,
    redirectTo,
    onQueryChange,
    onSearchSubmit,
    onSearchClear,
  };
};
