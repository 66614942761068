import { useEffect, useRef } from 'react';

import cx from 'classnames';

import { Event, trackEvent } from '~/ui/components/analytics';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { getLabelForNavItem } from '~/v1/components/navigation/navigation.utils';

import styles from './primary.module.scss';
import { type NavigationItem } from '../navigation.interface';

interface NavigationItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  item: NavigationItem;
  setActiveNavigation: (navigationItem: NavigationItem | undefined) => void;
  activeNavigation: NavigationItem | undefined;
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  item,
  setActiveNavigation,
  activeNavigation,
  onClick,
  children,
}) => {
  const label = getLabelForNavItem(item);

  return (
    <button
      className={cx(styles.navigationItem, {
        [styles.navigationItemActive]: item === activeNavigation,
      })}
      onClick={event => {
        if (item === activeNavigation) {
          setActiveNavigation(undefined);
          return;
        }
        trackEvent(Event.NAVIGATION_PRIMARY, {
          navigationCategory: label,
        });
        setActiveNavigation(item);
        onClick?.(event);
      }}
      aria-expanded={item === activeNavigation}
    >
      {label}
      {children}
    </button>
  );
};

type NavigationItemsProps = {
  className?: string;
  setActiveNavigation: (navigationItem: NavigationItem | undefined) => void;
  activeNavigation: NavigationItem | undefined;
  onSearchClick?: () => void;
};

export const NavigationItems: React.FC<NavigationItemsProps> = ({
  className,
  setActiveNavigation,
  activeNavigation,
  onSearchClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const height = element.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--primary-navigation-height', `${height / 10}rem`);
  }, []);

  return (
    <div className={cx(styles.navigationItemsWrapper, className)} ref={ref}>
      <NavigationItem
        item="ideas"
        activeNavigation={activeNavigation}
        setActiveNavigation={setActiveNavigation}
      />
      <NavigationItem
        item="pages"
        activeNavigation={activeNavigation}
        setActiveNavigation={setActiveNavigation}
      />
      <NavigationItem
        item="search"
        activeNavigation={activeNavigation}
        setActiveNavigation={setActiveNavigation}
        onClick={onSearchClick}
      >
        <Icon type={IconType.Search} className={styles.searchIcon} />
      </NavigationItem>
    </div>
  );
};
