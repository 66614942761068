import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { Route } from '~/v1/constants/route';
import { StorageKey } from '~/v1/constants/storageKey';
import { ThemeContext } from '~/v1/system/theme/theme.context';

interface UseLayoutHook {
  layoutLoaded: boolean;
}

export const useLayoutHook = (): UseLayoutHook => {
  const router = useRouter();
  const {
    rootTheme: { primary },
  } = useContext(ThemeContext);
  const [layoutLoaded, setLayoutLoaded] = useState(false);

  // TODO: This reruns on every navigation. As a followup, this logic should be
  //  moved into the layout component and only run on render
  useEffect(() => {
    const t = setTimeout(() => {
      setLayoutLoaded(true);
    }, 300);

    if (router.pathname !== Route.HOME) {
      Cookies.set(StorageKey.INTRO_ANIMATION, primary, { expires: 1 });
    }

    return () => clearTimeout(t);
  }, [primary, router.pathname]);

  return {
    layoutLoaded,
  };
};
