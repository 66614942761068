import { type QuickLink, type QuickLink_internalLink } from '~/v1/_types/QuickLink';
import { createLink } from '~/v1/components/link/link.utils';
import { Route } from '~/v1/constants/route';

import { type LinkData } from './quickLinks';
import { QuickLinkContentSubtype, QuickLinkContentType } from './quickLinks.interface';
import { type FilterUrlType } from '../filter/filter.interface';

type QuickLinkType = 'asset' | 'externalLink' | 'internalLink';
type QuickLinkWithType<Required extends QuickLinkType> = {
  [K in keyof QuickLink]: K extends Required
    ? NonNullable<QuickLink[K]>
    : K extends QuickLinkType
      ? null
      : QuickLink[K];
};

function isQuickLink(link: QuickLink): link is QuickLinkWithType<QuickLinkType> {
  return link.asset !== null || link.externalLink !== null || link.internalLink !== null;
}

export const mapQuickLinks = (
  links: QuickLink[],
  filters?: Record<string, FilterUrlType[]>,
): LinkData[] => {
  return links.filter(isQuickLink).map(link => {
    if (link.externalLink) {
      return createLink({
        type: 'external',
        href: link.externalLink,
        label: link.label,
      });
    }

    if (link.internalLink) {
      const [route, slug] = getInternalLinkHref(link.internalLink);
      return createLink({
        type: 'internal',
        href: {
          // TODO: add typeguard
          route: route as Route,
          slug,
          filters,
        },
        label: link.label,
      });
    }

    return createLink({
      type: 'asset',
      href: link.asset.src,
      label: link.label,
    });
  });
};

// TODO: There has to be a better way to do this
function getInternalLinkHref(link: QuickLink_internalLink): [string] | [string, string] {
  const BY_SUBTYPE: Record<string, [string] | [string, string]> = {
    [QuickLinkContentSubtype.GENERIC]: [Route.ARTICLE, link.slug],
    [QuickLinkContentSubtype.GRANTEE]: [Route.NEWS, link.slug],
    [QuickLinkContentSubtype.MELLON_IN_THE_NEWS]: [Route.NEWS, link.slug],
    [QuickLinkContentSubtype.PAST_EVENT]: [Route.EVENTS, link.slug],
    [QuickLinkContentSubtype.REPORT]: [Route.REPORT, link.slug],
    [QuickLinkContentSubtype.VOICES]: [Route.VOICES, link.slug],
  };

  const BY_TYPE: Record<string, [string] | [string, string] | undefined> = {
    [QuickLinkContentType.IDEA]: [Route.IDEA, link.slug],
    [QuickLinkContentType.GRANT_ACTIVITY_ARTICLES]: [Route.GRANT_STORY, link.slug],
    [QuickLinkContentType.GRANT_MAKING_AREA_DETAILS]: [Route.GRANT_MAKING_AREAS, link.slug],
    [QuickLinkContentType.GRANTMAKING_AREAS]: [Route.GRANT_MAKING_AREAS],
    [QuickLinkContentType.GRANT_RESOURCES]: [Route.GRANT_RESOURCES],
    [QuickLinkContentType.FINANCIALS]: [Route.FINANCIALS],
    [QuickLinkContentType.HISTORY]: [Route.HISTORY],
    [QuickLinkContentType.NEWS]: [Route.NEWS],
    [QuickLinkContentType.EVENTS]: [Route.EVENTS],
    [QuickLinkContentType.MMUF]: [Route.MMUF],
    [QuickLinkContentType.PRESS_ROOM]: [Route.PRESS_ROOM],
    [QuickLinkContentType.CONTACT]: [Route.CONTACT],
    [QuickLinkContentType.PRIVACY_POLICY]: [Route.PRIVACY_POLICY],
    [QuickLinkContentType.TERMS_OF_USE]: [Route.TERMS_OF_USE],
    [QuickLinkContentType.GRANT_SEARCH_FILTERS]: [Route.GRANT_SEARCH],
    [QuickLinkContentType.PERSON]: [Route.PEOPLE, link.slug],
    [QuickLinkContentType.PEOPLE]: [Route.PEOPLE],
    [QuickLinkContentType.MISSION]: [Route.MISSION],
    [QuickLinkContentType.ANNUAL_REPORT]: [Route.ANNUAL_REPORT, link.slug],
    [QuickLinkContentType.ARTICLE]: link.subType ? BY_SUBTYPE[link.subType] : undefined,
  };

  return BY_TYPE[link.contentType] ?? [`/${link?.slug}`];
}
