import { useEffect } from 'react';

import cx from 'classnames';

import { GridGuidelines } from '~/ui/components/grid/guidelines';
import { type Footer as FooterQuery } from '~/v1/_types/Footer';
import { type MainNavigation } from '~/v1/_types/MainNavigation';
import { Navigation } from '~/v1/components/navigation/navigation';
import { mapNavigationItems } from '~/v1/components/navigation/navigation.utils';
import { PrimaryNavigationPlaceholder } from '~/v1/components/navigation/placeholder/placeholder';
import { PAGE_LAYOUT } from '~/v1/constants/elementIds';

import { useLayoutHook } from './layout.hook';
import styles from './layout.module.scss';
import { Footer } from '../../modules/footer/footer';

interface LayoutProps {
  children: React.ReactNode;
  navigation?: MainNavigation | null;
  desktopNavContent?: React.ReactNode;
  showDesktopNavContent?: boolean;
  featuredSearches: string[];
  isHomepage?: boolean;
  footer: FooterQuery | null;
  isCondensedFooter?: boolean;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({
  navigation,
  desktopNavContent,
  showDesktopNavContent,
  featuredSearches,
  footer,
  children,
  isCondensedFooter = false,
  isHomepage,
  className,
}) => {
  const { layoutLoaded } = useLayoutHook();

  useEffect(() => {
    const onResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    // onResize should run once on mount
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div
      id={PAGE_LAYOUT}
      className={cx(styles.root, className, {
        [styles.active]: layoutLoaded,
      })}
    >
      <div className={styles.page}>
        {navigation && (
          <Navigation
            navigation={mapNavigationItems(navigation, featuredSearches)}
            desktopPrimaryContent={desktopNavContent}
            showDesktopPrimaryContent={showDesktopNavContent}
          />
        )}

        <div
          className={cx(styles.main, {
            [styles.mainHomepage]: isHomepage,
          })}
        >
          <PrimaryNavigationPlaceholder />
          <main className={styles.content}>{children}</main>
          <GridGuidelines />
        </div>
      </div>

      {footer && <Footer footer={footer} isCondensed={isCondensedFooter} />}
    </div>
  );
};
