export enum SubscriptionStatus {
  DEFAULT = 'default',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  ALREADY_SUBSCRIBED = 'alreadySubscribed',
}

export enum NewsLetterId {
  FOOTER = 'footer_newsletter_subscription',
  HOME = 'homepage_newsletter_subscription',
  GRANT_RESOURCES = 'grant_resources_newsletter_subscription',
  UPCOMING_EVENTS = 'upcoming_events_newsletter_subscription',
  MMUF = 'mmuf_newsletter_subscription',
  CONTACT = 'contact_newsletter_subscription',
  FEATURE = 'feature_newsletter_subscription',
}

export interface NewsletterProps {
  id: NewsLetterId;
  className?: string;
  disclaimer?: string | null;
  disclaimerLinkLabel?: string;
  disclaimerLinkHref?: string;
  title?: string | null;
  actionLabel?: string;
  inputLabel?: string;
  noPadding?: boolean;
  isInFooter?: boolean;
  onSubmit?: (email: string) => Promise<Response>;
}
