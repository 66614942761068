import { type SuggestionContentType } from '~/v1/_types/globalTypes';

import { type SearchSuggestionResponse, type SearchSuggestionType } from '../search.interface';

export const mapSuggestions = (
  suggestions: SearchSuggestionResponse | null,
): SearchSuggestionType[] => {
  return suggestions
    ? [suggestions.primarySuggestions, suggestions.secondarySuggestions].map(item => ({
        type: item?.contentType as SuggestionContentType,
        suggestions: item?.entities as string[],
      }))
    : [];
};
