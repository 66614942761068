import { datadogRum } from '@datadog/browser-rum-slim';
import { sendGTMEvent } from '@next/third-parties/google';

import { type Event, type EventMap } from '~/ui/components/analytics/types';

type EventWithData = {
  [E in Event]: EventMap[E] extends never ? never : E;
}[Event];
type EventWithoutData = {
  [E in Event]: EventMap[E] extends never ? E : never;
}[Event];
type CustomEvent = Exclude<string, Event>;
export type EventOrCustomEvent = EventWithData | EventWithoutData | CustomEvent;

export type TrackEventProps<E extends EventOrCustomEvent = EventOrCustomEvent> =
  E extends EventWithData
    ? { name: E; data: EventMap[E] }
    : E extends EventWithoutData
      ? { name: E }
      : { name: E; data?: Record<string, unknown> };

export function trackEvent<E extends EventOrCustomEvent>(props: TrackEventProps<E>): void;
export function trackEvent<E extends EventWithData>(event: E, data: EventMap[E]): void;
export function trackEvent<E extends EventWithoutData>(event: E, data?: EventMap[E]): void;
export function trackEvent<E extends CustomEvent>(event: E, data?: Record<string, unknown>): void;
export function trackEvent<E extends EventOrCustomEvent>(
  event: E | TrackEventProps<E>,
  data?: Record<string, unknown>,
): void {
  if (typeof event === 'object') {
    // @ts-expect-error lots of issues here
    return trackEvent<E>(event.name, event.data);
  }
  sendGTMEvent({
    event,
    page: window.location.href,
    ...data,
  });
  datadogRum.addAction(event, data);
}
