import { type MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface UseScrollLock<T> {
  ref: MutableRefObject<T | null>;
}

/** @deprecated use an Ariakit Portal */
export const useScrollLock = <T>(shouldLock: boolean, dependency?: string): UseScrollLock<T> => {
  const ref = useRef<T | null>(null);

  const resolveLock = useCallback(
    (target: HTMLElement) => {
      return shouldLock ? disableBodyScroll(target) : enableBodyScroll(target);
    },
    [shouldLock],
  );

  useEffect(() => {
    const { current } = ref;
    const target = current as HTMLElement;

    if (target) {
      resolveLock(target);
    }

    return () => target && enableBodyScroll(target);
  }, [shouldLock, resolveLock, dependency]);

  return {
    ref,
  };
};
