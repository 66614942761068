import { type SocialIcon } from './social.interface';
import { IconType } from '../icons/icon.interfaces';

export const mapSocialIcons = ({
  threadsLink,
  facebookLink,
  linkedInLink,
  instagramLink,
  youtubeLink,
}: Record<string, string>): { icons: SocialIcon[] } => {
  return {
    icons: [
      {
        link: facebookLink,
        type: IconType.Facebook,
        ariaLabel: 'Facebook',
      },
      {
        link: instagramLink,
        type: IconType.Instagram,
        ariaLabel: 'Instagram',
      },
      {
        link: linkedInLink,
        type: IconType.Linkedin,
        ariaLabel: 'Linkedin',
      },

      {
        link: threadsLink,
        type: IconType.Threads,
        ariaLabel: 'Threads',
      },
      {
        link: youtubeLink,
        type: IconType.Youtube,
        ariaLabel: 'Youtube',
      },
    ],
  };
};
