// ----- ATTENTION -----
//
// Do not make edits in this file, it is generated by a script.
// Check "generate-icons.js" for more details.
// To rebuild it, run "yarn generate-icons" from the root folder.

export enum IconType {
  'ArrowDiagonal' = 'ArrowDiagonal',
  'ArrowDown' = 'ArrowDown',
  'ArrowLeft' = 'ArrowLeft',
  'ArrowLevelDown' = 'ArrowLevelDown',
  'ArrowRight' = 'ArrowRight',
  'ArrowUp' = 'ArrowUp',
  'Camera' = 'Camera',
  'CardLogo' = 'CardLogo',
  'CcOff' = 'CcOff',
  'CcOn' = 'CcOn',
  'Check' = 'Check',
  'Chevron' = 'Chevron',
  'Close' = 'Close',
  'Envelope' = 'Envelope',
  'ExpandingArrowDiagonal' = 'ExpandingArrowDiagonal',
  'ExpandingArrowLeft' = 'ExpandingArrowLeft',
  'ExpandingArrowRight' = 'ExpandingArrowRight',
  'FacebookSquare' = 'FacebookSquare',
  'Facebook' = 'Facebook',
  'Filter' = 'Filter',
  'Globe' = 'Globe',
  'GreatWallGrid' = 'GreatWallGrid',
  'GreatWallScroller' = 'GreatWallScroller',
  'HeadphoneOff' = 'HeadphoneOff',
  'Headphone' = 'Headphone',
  'Instagram' = 'Instagram',
  'LineDiagonal' = 'LineDiagonal',
  'Line' = 'Line',
  'Link' = 'Link',
  'Linkedin' = 'Linkedin',
  'Loading' = 'Loading',
  'LocationPin' = 'LocationPin',
  'Lock' = 'Lock',
  'Logo404' = 'Logo404',
  'LogoFooter' = 'LogoFooter',
  'LogoLarge' = 'LogoLarge',
  'LogoNews' = 'LogoNews',
  'LogoWorded' = 'LogoWorded',
  'Logo' = 'Logo',
  'Maximize' = 'Maximize',
  'Microphone' = 'Microphone',
  'Minimize' = 'Minimize',
  'Minus' = 'Minus',
  'Pause' = 'Pause',
  'Pdf' = 'Pdf',
  'Phone' = 'Phone',
  'Play' = 'Play',
  'Plus' = 'Plus',
  'Print' = 'Print',
  'Reload' = 'Reload',
  'Search' = 'Search',
  'Share' = 'Share',
  'Sort' = 'Sort',
  'Threads' = 'Threads',
  'Tick' = 'Tick',
  'Twitter' = 'Twitter',
  'Union' = 'Union',
  'User' = 'User',
  'Volume' = 'Volume',
  'Youtube' = 'Youtube',
}
