export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Theme = 'theme',
}

export enum ButtonMode {
  Light = 'light',
  Dark = 'dark',
}

export enum ButtonSize {
  Large = 'large',
  Tiny = 'tiny',
  Icon = 'icon',
  Medium = 'medium',
}
