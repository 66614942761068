import cx from 'classnames';

import { TEST_ID } from '~/v1/constants/testId';

import { SearchSuggestion } from './suggestion/suggestion';
import { SearchSuggestionSkeleton } from './suggestion/suggestion.skeleton';
import styles from './suggestions.module.scss';
import { useSearchContext } from '../search.context';

export const SearchSuggestions: React.FC = () => {
  const { suggestions, suggestionsQuery, isLoading } = useSearchContext();

  const noResults =
    suggestions !== null ? (
      <p
        tabIndex={0}
        className={cx(styles.noResults, 'bodySmallTypography')}
        data-test-id={TEST_ID.SEARCH.SUGGESTED_TITLE}
      >
        No suggested searches found for &lsquo;{suggestionsQuery}&rsquo;
      </p>
    ) : null;

  return isLoading ? (
    <SearchSuggestionSkeleton />
  ) : suggestions && suggestions.length ? (
    <>
      {suggestions.map((suggestion, index) => (
        <SearchSuggestion key={index} {...suggestion} />
      ))}
    </>
  ) : (
    noResults
  );
};
