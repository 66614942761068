import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { Event, trackEvent } from '~/ui/components/analytics';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';

import { type NavigationItemData } from './secondary.interface';
import styles from './secondary.module.scss';
import { type NavigationItem } from '../navigation.interface';

type SecondaryNavigationItemsProps = {
  items?: NavigationItemData[];
  activeNavigation: NavigationItem | undefined;
  onMouseEnter: (item: NavigationItemData) => void;
  onMouseLeave: () => void;
  isDesktop?: boolean;
};

export const SecondaryNavigationItems: React.FC<SecondaryNavigationItemsProps> = ({
  items,
  activeNavigation,
  onMouseEnter,
  onMouseLeave,
  isDesktop,
}) => {
  const innerImageClasses = cx(styles.secondaryNavigationItemImage, styles.linkImage);
  const additionalDelay = isDesktop && !activeNavigation ? 0.4 : 0;

  return (
    <nav>
      <AnimatePresence>
        {items?.map(
          (item, index) =>
            item && (
              <motion.div
                className={styles.secondaryNavigationItem}
                key={`${item.label}-${index}`}
                initial={{ opacity: 0, transform: 'translateX(-10px)' }}
                animate={{ opacity: 1, transform: 'translateX(0px)' }}
                exit={{
                  opacity: 0,
                  transform: 'translateX(-10px)',
                  transition: {
                    duration: 0,
                  },
                }}
                transition={{ duration: 0.5, delay: additionalDelay + index * 0.05 }}
              >
                <Link
                  onMouseEnter={() => onMouseEnter(item)}
                  onMouseLeave={onMouseLeave}
                  href={{
                    pathname: item.href,
                    query: { follow: false },
                  }}
                  as={item.href}
                  onClick={() =>
                    trackEvent(Event.NAVIGATION_SECONDARY, {
                      navigationCategory: activeNavigation,
                      navigationItem: item.label,
                      navigationLink: item.href,
                    })
                  }
                  className={styles.secondaryNavigationItemLink}
                >
                  <Image
                    src={item.image.src}
                    alt={item.image.title}
                    className={innerImageClasses}
                    fill={false}
                    width={48}
                    height={64}
                    aspectRatio="2/3"
                  />
                  {item.label}
                </Link>
              </motion.div>
            ),
        )}
      </AnimatePresence>
    </nav>
  );
};
