import { gql } from '@apollo/client';

import { SUGGESTIONS_FRAGMENT } from '../fragments/suggestion';

export const SUGGESTIONS_QUERY = gql`
  query SuggestionsQuery($term: String!) {
    globalAutocomplete(term: $term) {
      primarySuggestions {
        ...Suggestion
      }
      secondarySuggestions {
        ...Suggestion
      }
    }
  }
  ${SUGGESTIONS_FRAGMENT}
`;
