import { motion } from 'framer-motion';

import { Search } from '~/v1/components/search/search';
import { SearchGtmType, type SearchSuggestions } from '~/v1/components/search/search.interface';
import { SearchManager } from '~/v1/components/search/search.manager';
import { SearchSuggestion } from '~/v1/components/search/suggestions/suggestion/suggestion';
import { Route } from '~/v1/constants/route';
import { getSuggestions } from '~/v1/graphql/actions/client/getSuggestions';

import styles from '../secondary.module.scss';

interface SecondaryNavigationSearchProps extends React.HTMLAttributes<HTMLInputElement> {
  searchSuggestions?: SearchSuggestions;
}

export const SecondaryNavigationSearch: React.FC<SecondaryNavigationSearchProps> = ({
  searchSuggestions,
  ...props
}) => {
  return (
    <motion.div
      className={styles.searchWrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0,
        },
      }}
      transition={{ delay: 0.2, duration: 0.5, ease: [0.12, 0, 0.39, 0] }}
    >
      <SearchManager
        isGlobal
        gtm={SearchGtmType.GLOBAL}
        api={getSuggestions}
        redirectTo={Route.SEARCH}
      >
        <Search isThemed {...props}>
          {searchSuggestions && (
            <SearchSuggestion label="Featured searches" suggestions={searchSuggestions} />
          )}
        </Search>
      </SearchManager>
    </motion.div>
  );
};
