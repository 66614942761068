import NextImage, { type ImageLoaderProps, type ImageProps as NextImageProps } from 'next/image';
import { type ReactEventHandler, useContext, useState } from 'react';

import cx from 'classnames';

import { ColumnContext } from '~/ui/components/grid';
import { toBase64 } from '~/v1/utils/toBase64';

import {
  type AspectRatio,
  type IImage,
  type ImageColSpan,
  ImageOrientation,
} from './image.interface';
import styles from './image.module.scss';
import { getSizesFromSpan } from './image.utils';

interface BaseImageProps extends IImage {
  onAspectRatio?: (aspectRatio: AspectRatio) => void;
  colSpan?: ImageColSpan;
  width?: number;
  height?: number;
  /** @default true */
  fill?: boolean;
  priority?: boolean;
}

export type ImageProps = BaseImageProps & Omit<NextImageProps, keyof BaseImageProps>;

// this return type is required to tell Next.js that we are using a base64 placeholder
export function getImagePlaceholder(w: number, h: number): `data:image/${string}` {
  const shimmer = `<svg width="${w}" height="${h}" style="opacity: 0.08;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <rect id="r" width="${w}" height="${h}" fill="#000" />
  <animate xlink:href="#r" attributeName="opacity" values="0.2;0.8;0.2" dur="1700ms" repeatCount="indefinite" />
</svg>`;
  return `data:image/svg+xml;base64,${toBase64(shimmer)}`;
}

export const imageLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  const encodedSrc = encodeURI(src);
  const format = encodedSrc.endsWith('.svg') ? '' : 'fm=webp';

  const widthParam = encodedSrc.includes('?')
    ? `&w=${width}&${format}&q=${quality ?? 90}`
    : `?w=${width}&${format}&q=${quality ?? 90}`;

  return `${encodedSrc}${widthParam}`;
};

export const Image: React.FC<ImageProps> = ({
  className,
  children,
  colSpan,
  aspectRatio,
  onAspectRatio,
  onLoad,
  alt,
  ...rest
}) => {
  const columns = useContext(ColumnContext);
  const [aspectRatioInner, setAspectRatioInner] = useState<number>(0);

  const handleLoad: ReactEventHandler<HTMLImageElement> = e => {
    const { naturalHeight, naturalWidth } = e.target as HTMLImageElement;

    const aspectRatio = naturalWidth / naturalHeight;
    setAspectRatioInner(aspectRatio);
    onAspectRatio?.({
      orientation:
        naturalWidth > naturalHeight ? ImageOrientation.LANDSCAPE : ImageOrientation.PORTRAIT,
      ratio: aspectRatio,
    });

    onLoad?.(e);
  };

  return (
    <div
      className={cx(styles.imageContainer, className)}
      style={{ aspectRatio: aspectRatio ?? aspectRatioInner }}
    >
      <NextImage
        // TODO: Can the shimmer size not be hardcoded?
        placeholder={getImagePlaceholder(200, 200)}
        loader={imageLoader}
        onLoad={handleLoad}
        sizes={getSizesFromSpan(colSpan || columns)}
        fill
        alt={alt ?? ''}
        {...rest}
      />
      {children}
    </div>
  );
};
