import cx from 'classnames';

import { TEST_ID } from '~/v1/constants/testId';

import { useEntryHook } from './entry.hook';
import styles from './entry.module.scss';
import { Icon } from '../../icons/icon';
import { IconType } from '../../icons/icon.interfaces';
import { useSearchContext } from '../search.context';

interface SearchEntryProps extends React.HTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

export const SearchEntry: React.FC<SearchEntryProps> = ({ placeholder, ...props }) => {
  const { query, onSearchSubmit, isGlobal } = useSearchContext();
  const { ref, onChange, onFocus, onClear, isOverflowing, isNewSearch } = useEntryHook();

  return (
    <div className={styles.entry}>
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!!isGlobal}
        ref={ref}
        value={query || ''}
        onChange={onChange}
        className={cx(styles.input, 'pageTitleSmallTypography', {
          [styles.overflow]: isOverflowing,
        })}
        placeholder={placeholder || 'Search'}
        aria-label={placeholder || 'Search'}
        data-test-id={TEST_ID.SEARCH.INPUT}
        {...props}
      />

      {isOverflowing && <button className={styles.focusHandler} onClick={onFocus} aria-hidden />}

      <button
        className={cx(styles.action, {
          [styles.isDisabled]: !query,
        })}
        disabled={!query}
        onClick={isGlobal || isNewSearch ? onSearchSubmit : onClear}
        aria-label={isGlobal || isNewSearch ? 'Submit search' : 'Clear search'}
        data-test-id={TEST_ID.SEARCH.BUTTON}
      >
        <Icon
          className={styles.icon}
          type={
            query && query.length
              ? isGlobal || isNewSearch
                ? IconType.ArrowRight
                : IconType.Close
              : IconType.Search
          }
        />
      </button>
      <div className={styles.border} />
    </div>
  );
};
