import NextLink from 'next/link';

import cx from 'classnames';

import { Event, trackEvent } from '~/ui/components/analytics';
import { SuggestionContentType } from '~/v1/_types/globalTypes';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './suggestion.module.scss';
import { useSearchContext } from '../../search.context';
import { SearchGtmType, type SearchSuggestionType } from '../../search.interface';

const SUGGESTION_TYPES: Record<SuggestionContentType, string> = {
  [SuggestionContentType.ARTICLE]: 'articles',
  [SuggestionContentType.EVENT]: 'events',
  [SuggestionContentType.GRANT]: 'grants',
  [SuggestionContentType.GRANTEE]: 'grantee',
  [SuggestionContentType.IDEA]: 'Ideas',
  [SuggestionContentType.NEWS]: 'news',
  [SuggestionContentType.PERSON]: 'people',
  [SuggestionContentType.ABOUT_MELLON]: 'Mellon',
  [SuggestionContentType.FEATURE]: 'Features',
};

type SearchSuggestionProps = SearchSuggestionType;

export const SearchSuggestion: React.FC<SearchSuggestionProps> = ({ label, type, suggestions }) => {
  const { redirectTo, gtm } = useSearchContext();

  const suggestionClickEvent =
    gtm === SearchGtmType.GLOBAL
      ? Event.SEARCH_SUGGESTION_CLICK
      : Event.DATABASE_SEARCH_SUGGESTION_CLICK;

  const title = type ? `Suggested ${SUGGESTION_TYPES[type]}` : '';

  return suggestions.length ? (
    <div className={styles.suggestion}>
      <p
        className={cx(styles.title, 'bodySmallTypography')}
        data-test-id={TEST_ID.SEARCH.SUGGESTED_TITLE}
      >
        {label || title}
      </p>
      <ul className={styles.list}>
        {suggestions.map((suggestion, index) => (
          <li
            key={index}
            className={cx(styles.listItem, 'bodyLargeTypography')}
            data-test-id={TEST_ID.SEARCH.SUGGESTED_ITEM}
          >
            <NextLink
              className={cx(styles.link, 'truncate-1')}
              href={{
                href: `${redirectTo}/${encodeURIComponent(suggestion)}?phrase=1`,
                query: { follow: false },
              }}
              as={`${redirectTo}/${encodeURIComponent(suggestion)}?phrase=1`}
              onClick={() =>
                trackEvent(suggestionClickEvent, {
                  searchSuggestion: suggestion,
                })
              }
            >
              {suggestion}
            </NextLink>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};
