import { useEffect, useState } from 'react';

import cx from 'classnames';

import styles from './text.module.scss';
import { Icon } from '../../icons/icon';
import { IconType } from '../../icons/icon.interfaces';

export type ValidationStateType = 'Success' | 'Error' | '';

interface FieldTextProps extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  className?: string;
  helperText?: string;
  disabled?: boolean;
  id: string;
  validationRegEx?: RegExp;
  maxLength?: number;
  buttonLabel?: string;
  onActionClick?: (value: string) => void;
  handleBlur?: (value: string) => void;
  handleFocus?: () => void;
  isLoading?: boolean;
  pattern?: string;
  isThemed?: boolean;
  errorText?: string;
  shouldReset?: boolean;
}

export const FieldText: React.FC<FieldTextProps> = ({
  label,
  className,
  helperText,
  disabled,
  id,
  maxLength,
  validationRegEx,
  buttonLabel,
  onActionClick,
  handleBlur,
  handleFocus,
  pattern,
  isLoading,
  isThemed,
  onKeyDown,
  errorText,
  shouldReset,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (shouldReset) {
      setValue('');
      setIsValid(true);
    }
  }, [shouldReset]);

  const onButtonClick = (value: string) => {
    if (validationRegEx) {
      const isMatch = validationRegEx.test(value);
      setIsValid(isMatch);
      isMatch && onActionClick && !isLoading && onActionClick(value);
    }
  };

  const validateInput = (value: string) => {
    if (validationRegEx) {
      const isMatch = validationRegEx.test(value);
      setIsValid(isMatch);

      return isMatch;
    }

    return true;
  };

  const onBlur = () => {
    setIsFocused(false);
    return validateInput(value) ? handleBlur && handleBlur(value) : null;
  };

  const onFocus = () => {
    setIsFocused(true);
    handleFocus && handleFocus();
  };

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsValid(true);
  };

  const hasLabelOnTop = isFocused || value;

  const inputOuterWrapperClasses = cx(styles.outerWrapper, className, {
    [styles.outerWrapperDisabled]: disabled,
    [styles.outerWrapperThemed]: isThemed,
  });

  const hasActionButton = onActionClick && buttonLabel;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' && hasActionButton) {
      const target = e.target as HTMLInputElement;
      onButtonClick(target.value);
    }
    onKeyDown && onKeyDown(e);
  };

  return (
    <div className={inputOuterWrapperClasses}>
      <label
        htmlFor={id}
        id={`label-${id}`}
        className={cx(styles.label, {
          [styles.labelActive]: hasLabelOnTop,
          [styles.labelDisabled]: disabled && !value,
        })}
      >
        {label}
      </label>
      <div className={styles.innerWrapper}>
        <input
          id={id}
          className={cx(styles.textInput, {
            [styles.textInputDisabled]: disabled,
          })}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete="off"
          onChange={handleChange}
          type="text"
          disabled={disabled}
          maxLength={maxLength}
          pattern={pattern ? pattern : undefined}
          onKeyDown={handleKeyDown}
          aria-invalid={!isValid}
          value={value}
          {...props}
        />
        <div className={styles.inputBorder} />
        {hasActionButton &&
          (isLoading ? (
            <Icon
              aria-live="polite"
              aria-label="loading"
              className={styles.loadingIcon}
              type={IconType.Loading}
            />
          ) : (
            <button
              aria-disabled={!isValid || disabled}
              tabIndex={0}
              className={styles.actionButton}
              onClick={() => onButtonClick(value)}
            >
              {buttonLabel}
              &nbsp;
              <Icon className={styles.actionIcon} type={IconType.ExpandingArrowRight} />
            </button>
          ))}
      </div>
      {(!isValid || errorText) && (
        <div className={cx(styles.helperText, 'captionTypography')}>
          <Icon className={styles.helperTextIcon} type={IconType.Union} aria-hidden />
          {errorText || helperText}
        </div>
      )}
    </div>
  );
};
