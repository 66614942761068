import { type Breakpoint } from '~/ui/styles/grid';

export enum ImageOrientation {
  LANDSCAPE,
  PORTRAIT,
}

export type ImageColSpan = Partial<Record<Breakpoint, number>>;

export interface AspectRatio {
  orientation: ImageOrientation;
  ratio: number;
}

export interface IImage {
  className?: string;
  src: string;
  // TODO: this should be required >:|
  alt?: string;
  title?: string;
  description?: string | null;
  aspectRatio?: string;
}
