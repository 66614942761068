'use client';

import { useEffect, useState } from 'react';

import { Breakpoint, MediaQuery } from '~/ui/styles/grid';

export function useBreakpoint(breakpoint: Breakpoint): boolean;
export function useBreakpoint(): Breakpoint;
/**
 * Hook to determine the current breakpoint.
 *
 * If no breakpoint is provided, it will return a {@link Breakpoint} enum value. If a
 * {@link Breakpoint} is provided, it will return a boolean indicating whether it is the current
 * breakpoint.
 */
export function useBreakpoint(breakpoint?: Breakpoint) {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>(Breakpoint.SM);

  useEffect(() => {
    const mediaQueries = Object.values(Breakpoint).reduce(
      (acc, bp) => ({
        ...acc,
        [bp]: window.matchMedia(MediaQuery[bp]),
      }),
      {} as Record<Breakpoint, MediaQueryList>,
    );
    const cleanupFns = Object.entries(mediaQueries).map(([breakpoint, mediaQuery]) => {
      // Set the initial breakpoint
      if (mediaQuery.matches) {
        setCurrentBreakpoint(breakpoint as Breakpoint);
      }
      const onChange = (event: MediaQueryListEvent) => {
        if (event.matches) {
          setCurrentBreakpoint(breakpoint as Breakpoint);
        }
      };
      mediaQuery.addEventListener('change', onChange);
      return () => mediaQuery.removeEventListener('change', onChange);
    });

    return () => cleanupFns.forEach(fn => fn());
  }, []);

  return breakpoint ? breakpoint === currentBreakpoint : currentBreakpoint;
}
