import { type MutableRefObject, useRef, useState } from 'react';

import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { Button } from '~/v1/components/button/button';
import { Icon } from '~/v1/components/icons/icon';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import styles from './primary.module.scss';
import { NavigationItems } from './primaryNavigationItems';
import { ButtonType } from '../../button/button.interface';
import { IconType } from '../../icons/icon.interfaces';
import { type AnimationDuration, type NavigationItem } from '../navigation.interface';

const ANIMATION_DURATION = 800;

interface PrimaryNavigationProps {
  className?: string;
  isLarge?: boolean;
  setActiveNavigation: (navigationItem: NavigationItem | undefined) => void;
  activeNavigation: NavigationItem | undefined;
  setPrimaryIsExpanded: (expanded: boolean) => void;
  primaryIsExpanded: boolean;
  isSticky?: boolean;
  scrollLockRef?: MutableRefObject<HTMLDivElement | null>;
  onSearchClick?: () => void;
  desktopContent?: React.ReactNode;
  showDesktopContent?: boolean;
}

export function PrimaryNavigation({
  className,
  isLarge,
  setActiveNavigation,
  activeNavigation,
  primaryIsExpanded,
  setPrimaryIsExpanded,
  scrollLockRef,
  isSticky,
  onSearchClick,
  desktopContent,
  showDesktopContent,
}: PrimaryNavigationProps) {
  const breakpoint = useBreakpoint();
  const [animationType, setAnimationType] = useState<AnimationDuration>('instant');
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const togglePrimary = () => {
    timer.current && clearTimeout(timer.current);
    setAnimationType('default');
    timer.current = setTimeout(() => {
      setAnimationType('instant');
    }, ANIMATION_DURATION);
    setPrimaryIsExpanded(!primaryIsExpanded);
  };

  const isSmallOrMedium = breakpoint === Breakpoint.SM || breakpoint === Breakpoint.MD;
  const closeNavInstant = animationType === 'instant';

  const navigationClasses = cx(styles.navigation, className, {
    [styles.navigationLarge]: isLarge,
  });

  const contentTopClasses = cx(styles.contentTop, {
    [styles.contentTopScrolled]: isSticky,
    [styles.contentTopHidden]: !!activeNavigation,
    [styles.contentTopInstant]: !(!primaryIsExpanded && !!activeNavigation),
  });

  const contentBottomClasses = cx(styles.contentBottom, {
    [styles.contentBottomExpanded]: primaryIsExpanded,
    [styles.contentBottomInstant]: closeNavInstant,
  });

  const wordmarkLogoClasses = cx(styles.wordmarkLogo, {
    [styles.wordmarkLogoScrolled]: isSticky,
  });

  return (
    <>
      <FocusTrap
        active={primaryIsExpanded && isSmallOrMedium}
        focusTrapOptions={{ initialFocus: false }}
      >
        <div className={navigationClasses} id="primary-navigation" ref={scrollLockRef}>
          <div className={contentTopClasses}>
            <div className={styles.contentTopInnerWrapper}>
              <Link
                href={{
                  pathname: Route.HOME,
                  query: { follow: false },
                }}
                as={Route.HOME}
                className={wordmarkLogoClasses}
                ariaLabel="homepage"
              >
                <Icon type={IconType.LogoWorded} />
              </Link>
              <button
                className={cx(styles.menuButton, 'linkTypography')}
                onClick={togglePrimary}
                aria-label={primaryIsExpanded ? 'Close navigation' : undefined}
              >
                {primaryIsExpanded ? (
                  <Icon className={styles.closeIcon} type={IconType.Close} />
                ) : (
                  'Menu'
                )}
              </button>
            </div>
          </div>
          <div className={contentBottomClasses}>
            <AnimatePresence>
              {(!isSmallOrMedium || primaryIsExpanded) && (
                <motion.div
                  className={cx(styles.contentBottomInnerWrapper, {
                    [styles.showDesktopContent]: showDesktopContent,
                  })}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.4,
                    },
                  }}
                  transition={isSmallOrMedium ? { delay: 0.5, duration: 0.8 } : {}}
                >
                  <div className={styles.alternativeContent}>{desktopContent}</div>
                  <div className={styles.defaultContent}>
                    <NavigationItems
                      activeNavigation={activeNavigation}
                      setActiveNavigation={setActiveNavigation}
                      onSearchClick={onSearchClick}
                    />
                    <Button
                      text="Grant Resources"
                      isTransparent
                      type={ButtonType.Secondary}
                      className={styles.grantResourcesButton}
                      href={{
                        pathname: Route.GRANT_RESOURCES,
                        query: { follow: false },
                      }}
                      as={Route.GRANT_RESOURCES}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </FocusTrap>
    </>
  );
}
