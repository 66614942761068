/**
 * WARNING: The values for the Breakpoint enum are used as props on the
 * {@link import('~/ui/modules/image').Image|Image} component. Changing
 * them will have downstream effects.
 */

import breakpointStyles from './breakpoints.module.scss';
import gridStyles from './grid.module.scss';

export enum Breakpoint {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export const MAX_GRID_WIDTH = parseInt(gridStyles.maxGridWidth, 10);

/**
 * Number of columns for each breakpoint. Nested grids may have fewer columns.
 * @enum {number}
 */
export const Columns: Record<Breakpoint, number> = {
  [Breakpoint.SM]: parseInt(gridStyles.columnsSm, 10),
  [Breakpoint.MD]: parseInt(gridStyles.columnsMd, 10),
  [Breakpoint.LG]: parseInt(gridStyles.columnsLg, 10),
} as const;

/**
 * Minimum viewport width for each breakpoint.
 * @enum {number}
 */
export const MinWidth: Record<Breakpoint, number> = {
  [Breakpoint.SM]: 0,
  [Breakpoint.MD]: parseInt(breakpointStyles.md, 10),
  [Breakpoint.LG]: parseInt(breakpointStyles.lg, 10),
} as const;

/**
 * Page margin for each breakpoint.
 * @enum {number}
 */
export const Margin: Record<Breakpoint, number> = {
  [Breakpoint.SM]: parseInt(gridStyles.marginSm, 10),
  [Breakpoint.MD]: parseInt(gridStyles.marginMd, 10),
  [Breakpoint.LG]: parseInt(gridStyles.marginLg, 10),
} as const;

/**
 * Media query (including min- and max-width as needed) for each breakpoint.
 * @enum {string}
 */
export const MediaQuery: Record<Breakpoint, string> = {
  [Breakpoint.SM]: `(max-width: ${MinWidth[Breakpoint.MD] - 1}px)`,
  [Breakpoint.MD]: `((min-width: ${MinWidth[Breakpoint.MD]}px) and (max-width: ${MinWidth[Breakpoint.LG] - 1}px))`,
  [Breakpoint.LG]: `(min-width: ${MinWidth[Breakpoint.LG]}px)`,
} as const;
