import { useEffect, useRef, useState } from 'react';

export function useSticky<T extends HTMLElement = HTMLElement>() {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setIsSticky(entry.target.getBoundingClientRect().y < 0),
      {
        threshold: [1],
      },
    );
    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  return {
    ref,
    isSticky,
  } as const;
}
