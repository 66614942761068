import { Event, trackEvent } from '~/ui/components/analytics';
import { type Footer_ideas } from '~/v1/_types/Footer';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import styles from './idea.module.scss';

type AvailableLinkProps = Omit<React.ComponentProps<typeof Link>, 'href'>;

export interface IdeaProps extends AvailableLinkProps {
  idea: Footer_ideas;
  showSeparator: boolean;
}

export function Idea({ idea, showSeparator, onClick, ...linkProps }: IdeaProps): JSX.Element {
  return (
    <Link
      href={{
        pathname: `${Route.IDEA}/${idea.slug}`,
        query: { follow: false },
      }}
      as={`${Route.IDEA}/${idea.slug}`}
      className={styles.ideaLink}
      onClick={e => {
        trackEvent(Event.NAVIGATION_FOOTER, {
          navigationItem: idea.name,
          navigationLink: `${Route.IDEA}/${idea.slug}`,
        });
        onClick?.(e);
      }}
      {...linkProps}
    >
      <Image
        src={idea.navigationImagePortrait.src}
        alt={idea.navigationImagePortrait.title}
        aspectRatio="2/3"
        width={48}
        height={64}
        fill={false}
        className={styles.ideaImage}
      />
      {idea.name}
      {showSeparator && <Icon type={IconType.LineDiagonal} aria-hidden />}
    </Link>
  );
}
