import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncFunc = (...props: any) => Promise<void>;

interface UseAsync {
  isLoading: boolean;
  async: (callback: AsyncFunc) => AsyncFunc;
}

export const useAsync = (): UseAsync => {
  const [isLoading, setIsLoading] = useState(false);

  const async =
    (callback: AsyncFunc): AsyncFunc =>
    async (...props) => {
      setIsLoading(true);

      await callback(...props);

      setIsLoading(false);
    };

  return {
    isLoading,
    async,
  };
};
