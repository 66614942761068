import { type PeoplePageQuery_mainNavigation } from '~/v1/_types/PeoplePageQuery';
import { Route } from '~/v1/constants/route';

import { type NavigationData, type NavigationItem } from './navigation.interface';

export function getLabelForNavItem(navItem: NavigationItem) {
  switch (navItem) {
    case 'ideas':
      return 'Explore Ideas';
    case 'pages':
      return 'Inside Mellon';
    case 'search':
      return 'Search';
  }
}

export const mapNavigationItems = (
  navigation: PeoplePageQuery_mainNavigation,
  featuredSearches: string[],
): NavigationData => {
  const ideas = navigation.ideas.map(idea => {
    return {
      label: idea.name,
      href: `${Route.IDEA}/${idea.slug}`,
      image: {
        src: idea.navigationImagePortrait.src,
        title: idea.navigationImagePortrait.title,
      },
    };
  });

  const pages = navigation.pages.map(page => {
    return {
      label: page.title,
      href: `/${page.slug}`,
      image: {
        src: page.navigationImagePortrait.src,
        title: page.navigationImagePortrait.title,
      },
    };
  });

  return {
    babyMissionStatement: navigation.babyMissionStatement || '',
    pages,
    ideas,
    suggestions: featuredSearches,
  };
};
