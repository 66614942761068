import { useRouter } from 'next/router';
import { type MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';

import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Breakpoint } from '~/ui/styles/grid';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { type SearchSuggestions } from '~/v1/components/search/search.interface';

import { SecondaryNavigationContent } from './content/content';
import { SecondaryNavigationSearch } from './search/search';
import { type NavigationItemData } from './secondary.interface';
import styles from './secondary.module.scss';
import { SECONDARY_NAVIGATION_ID } from '../navigation';
import { type AnimationDuration, type NavigationItem } from '../navigation.interface';

type SecondaryNavigationProps = {
  searchSuggestions?: SearchSuggestions;
  items: NavigationItemData[] | undefined;
  className?: string;
  setActivePrimaryNavigation: (navigationItem: NavigationItem | undefined) => void;
  statement: string;
  activePrimaryNavigation: NavigationItem | undefined;
  setPrimaryIsExpanded: (expanded: boolean) => void;
  scrollLockRef?: MutableRefObject<HTMLDivElement | null>;
  onSearchFocusChange?: () => void;
};

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({
  className,
  searchSuggestions,
  setActivePrimaryNavigation,
  items,
  statement,
  activePrimaryNavigation,
  scrollLockRef,
  setPrimaryIsExpanded,
  onSearchFocusChange,
}) => {
  const router = useRouter();
  const [breakpointHasChanged, setBreakpointHasChanges] = useState(false);
  const [animationType, setAnimationType] = useState<AnimationDuration>('default');
  const breakpoint = useBreakpoint();

  const isSmallOrMedium = breakpoint === Breakpoint.SM || breakpoint === Breakpoint.MD;
  const isActive = activePrimaryNavigation !== undefined;

  const wrapperClasses = cx(styles.secondaryNavigationWrapper, className, {
    [styles.secondaryNavigationWrapperActive]: isActive,
    [styles.noTransitions]: breakpointHasChanged,
  });

  const handleBackButton = () => {
    setAnimationType('instant');
    setTimeout(() => {
      setActivePrimaryNavigation(undefined);
      setAnimationType('default');
    }, 0);
  };

  const closeNavigation = useCallback(() => {
    setActivePrimaryNavigation(undefined);
    setPrimaryIsExpanded(false);
  }, [setPrimaryIsExpanded, setActivePrimaryNavigation]);

  useEffect(() => {
    closeNavigation();
  }, [router.query, closeNavigation]);

  useEffect(() => {
    setBreakpointHasChanges(true);
    setTimeout(() => setBreakpointHasChanges(false), 100);
  }, [breakpoint]);

  useEffect(() => {
    const onKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeNavigation();
      }
    };
    window.addEventListener('keydown', onKeypress);
    return () => window.removeEventListener('keydown', onKeypress);
  }, [closeNavigation]);

  const navigationClasses = cx(styles.secondaryNavigation, {
    [styles.secondaryNavigationActive]: isActive,
  });

  const firsFocusableElementRef = useRef(null);

  return (
    <div className={wrapperClasses}>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{
              transform: isSmallOrMedium ? 'translateY(0)' : 'translateX(-100%)',
            }}
            animate={{ transform: 'translateX(0%)' }}
            exit={{
              transform: isSmallOrMedium ? 'translateY(-100%)' : 'translateX(-100%)',
              transition: { duration: animationType === 'instant' ? 0 : 0.8 },
            }}
            transition={{ duration: 0.8, ease: [0.25, 1, 0.5, 1] }}
            className={navigationClasses}
          >
            <FocusTrap
              active={!!activePrimaryNavigation && isSmallOrMedium}
              focusTrapOptions={{ allowOutsideClick: true }}
            >
              <motion.div
                initial={{
                  opacity: 1,
                }}
                transition={{
                  duration: !activePrimaryNavigation ? 0 : 0.3,
                  ease: [0.33, 1, 0.68, 1],
                }}
                exit={{
                  opacity: 0,
                }}
                className={styles.navigationInnerWrapper}
                id={SECONDARY_NAVIGATION_ID}
                ref={scrollLockRef}
              >
                <div className={styles.secondaryNavigationTop}>
                  <button
                    className={cx(styles.topButton, styles.arrowButton)}
                    onClick={handleBackButton}
                    aria-label="Close secondary navigation"
                    ref={firsFocusableElementRef}
                  >
                    <Icon
                      type={IconType.ExpandingArrowLeft}
                      className={cx(styles.closeIcon, styles.arrowIcon)}
                    />
                  </button>
                  <p className={cx(styles.topLabel, 'bodySmallTypography')}>
                    {activePrimaryNavigation}
                  </p>
                  <div className={styles.topButtonWrapper}>
                    <button
                      aria-label="Close navigation"
                      className={styles.topButton}
                      onClick={closeNavigation}
                    >
                      <Icon type={IconType.Close} className={styles.closeIcon} />
                    </button>
                  </div>
                </div>
                <div className={styles.content}>
                  <AnimatePresence>
                    {activePrimaryNavigation !== 'search' ? (
                      <SecondaryNavigationContent
                        activeNavigation={activePrimaryNavigation}
                        items={items}
                        statement={statement}
                        isDesktop={!isSmallOrMedium}
                      />
                    ) : (
                      <SecondaryNavigationSearch
                        searchSuggestions={searchSuggestions}
                        onBlur={onSearchFocusChange}
                        onFocus={onSearchFocusChange}
                      />
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            </FocusTrap>
          </motion.div>
        )}
      </AnimatePresence>
      <button
        className={styles.backdropButton}
        onClick={closeNavigation}
        aria-hidden
        tabIndex={-1}
      />
    </div>
  );
};
