import { forwardRef } from 'react';

import cx from 'classnames';

import styles from './grid.module.scss';

export interface GridProps extends React.ComponentPropsWithoutRef<'div'> {
  /** @deprecated */
  noMaxWidth?: boolean;
  /** @deprecated */
  overflow?: {
    right?: boolean;
    left?: boolean;
  };
}

export const Grid = forwardRef(GridImpl);
function GridImpl(
  { children, className, ...rest }: GridProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div className={cx(styles.grid, className)} ref={ref} {...rest}>
      {children}
    </div>
  );
}
