import { type Suggestion } from '~/v1/_types/Suggestion';
import { type SuggestionContentType } from '~/v1/_types/globalTypes';
import { type getGrantSuggestions } from '~/v1/graphql/actions/client/getGrantSuggestion';
import { type getSuggestions } from '~/v1/graphql/actions/client/getSuggestions';

export type SearchSuggestions = string[];

export type SearchQueryFn = typeof getSuggestions | typeof getGrantSuggestions;

export type SearchSuggestionResponse = {
  primarySuggestions: Suggestion;
  secondarySuggestions: Suggestion;
};

export type SearchResultType = {
  id: string;
  title: string;
};

export enum SearchGtmType {
  GLOBAL,
  DATABASE,
}

export type SearchSuggestionType = {
  label?: string;
  type?: SuggestionContentType;
  suggestions: SearchSuggestions;
};

export type SearchContextType = {
  query: string;
  gtm: SearchGtmType;
  staticQuery: string;
  suggestionsQuery: string;
  suggestions: SearchSuggestionType[] | null;
  isLoading: boolean;
  isGlobal?: boolean;
  redirectTo: string;
  onQueryChange: (value: string) => void;
  onSearchSubmit: () => void;
  onSearchClear: () => void;
};
