import { SearchContextProvider } from './search.context';
import { useSearchHook } from './search.hook';
import { type SearchGtmType, type SearchQueryFn } from './search.interface';

interface SearchManagerProps {
  children: React.ReactNode;
  api: SearchQueryFn;
  isGlobal?: boolean;
  redirectTo: string;
  gtm: SearchGtmType;
  query?: string;
}

export const SearchManager: React.FC<SearchManagerProps> = ({
  api,
  gtm,
  children,
  isGlobal = false,
  redirectTo,
  query,
}) => {
  const value = useSearchHook({ isGlobal, redirectTo, api, gtm, query });

  return <SearchContextProvider value={value}>{children}</SearchContextProvider>;
};
