/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AboutMellonType {
  ANNUAL_REPORT = "ANNUAL_REPORT",
  CONTACT = "CONTACT",
  EVENTS_LANDING = "EVENTS_LANDING",
  FINANCIALS = "FINANCIALS",
  GRANT_MAKING_AREAS = "GRANT_MAKING_AREAS",
  GRANT_MAKING_AREA_DETAILS = "GRANT_MAKING_AREA_DETAILS",
  GRANT_RESOURCES = "GRANT_RESOURCES",
  HISTORY = "HISTORY",
  MISSION = "MISSION",
  MMUF = "MMUF",
  NEWS = "NEWS",
  PEOPLE = "PEOPLE",
  PRESS_ROOM = "PRESS_ROOM",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_USE = "TERMS_OF_USE",
  TRUSTEE = "TRUSTEE",
  TRUSTEES = "TRUSTEES",
}

export enum ArticleType {
  GENERIC = "GENERIC",
  GRANTEE = "GRANTEE",
  MELLON_IN_THE_NEWS = "MELLON_IN_THE_NEWS",
  PAST_EVENT = "PAST_EVENT",
  REPORT = "REPORT",
  VOICES = "VOICES",
}

export enum AtGlanceCardType {
  ARTICLE = "ARTICLE",
  GRANTS_DB_SEARCH = "GRANTS_DB_SEARCH",
  GRANT_MAKING_AREAS = "GRANT_MAKING_AREAS",
  HISTORY = "HISTORY",
  MISSION = "MISSION",
  NONE = "NONE",
  PEOPLE = "PEOPLE",
}

export enum ExternalEventType {
  EVENT = "EVENT",
  VIRTUAL_EVENT = "VIRTUAL_EVENT",
}

export enum FormattingOption {
  DROP_CAP = "DROP_CAP",
  TOMBSTONE = "TOMBSTONE",
}

export enum NewsSort {
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
}

export enum NewsType {
  ANNUAL_REPORT = "ANNUAL_REPORT",
  GRANTEE = "GRANTEE",
  MELLON_IN_THE_NEWS = "MELLON_IN_THE_NEWS",
  REPORT = "REPORT",
}

export enum SearchContentType {
  ABOUT_MELLON = "ABOUT_MELLON",
  ARTICLE = "ARTICLE",
  EVENT = "EVENT",
  FEATURE = "FEATURE",
  GRANT = "GRANT",
  GRANT_ACTIVITY_ARTICLE = "GRANT_ACTIVITY_ARTICLE",
  IDEA = "IDEA",
  NEWS = "NEWS",
  PERSON = "PERSON",
}

export enum SearchSort {
  A_Z = "A_Z",
  MOST_RELEVANT = "MOST_RELEVANT",
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
  Z_A = "Z_A",
}

export enum SuggestionContentType {
  ABOUT_MELLON = "ABOUT_MELLON",
  ARTICLE = "ARTICLE",
  EVENT = "EVENT",
  FEATURE = "FEATURE",
  GRANT = "GRANT",
  GRANTEE = "GRANTEE",
  IDEA = "IDEA",
  NEWS = "NEWS",
  PERSON = "PERSON",
}

export interface FilterRangeInput {
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
