import cx from 'classnames';

import { Column, Grid } from '~/ui/components/grid';

import { SearchEntry } from './entry/entry';
import { useSearchContext } from './search.context';
import { SEARCH_CHAR_LIMIT } from './search.hook';
import styles from './search.module.scss';
import { SearchSuggestions } from './suggestions/suggestions';

interface SearchProps extends React.HTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode | React.ReactNode[];
  isThemed?: boolean;
  placeholder?: string;
}

const SearchDefault: React.FC<SearchProps> = ({ children, placeholder, ...props }) => {
  const { query } = useSearchContext();

  return (
    <>
      <Grid className={styles.root}>
        <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
          <SearchEntry placeholder={placeholder} {...props} />
        </Column>
      </Grid>

      <div className={styles.border} />

      <Grid>
        <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
          {query && query.length && query.length >= SEARCH_CHAR_LIMIT ? (
            <SearchSuggestions />
          ) : (
            children
          )}
        </Column>
      </Grid>
    </>
  );
};

const SearchGlobal: React.FC<SearchProps> = ({ children, placeholder, ...props }) => {
  const { query } = useSearchContext();

  return (
    <>
      <SearchEntry placeholder={placeholder} {...props} />

      {query && query.length && query.length >= SEARCH_CHAR_LIMIT ? (
        <SearchSuggestions />
      ) : (
        children
      )}
    </>
  );
};

export const Search: React.FC<SearchProps> = ({ isThemed, ...props }) => {
  const { isGlobal } = useSearchContext();

  return (
    <div
      className={cx({
        themed: isThemed,
      })}
    >
      {isGlobal ? <SearchGlobal {...props} /> : <SearchDefault {...props} />}
    </div>
  );
};
