/** @deprecated */
export const TEST_ID = {
  AUDIO_PLAYER: 'audio-player',
  BUTTON: 'button',
  CALL_TO_ACTION: 'call-to-action',
  SKELETON: 'skeleton-loader',
  CARD: {
    LEADERSHIP: 'leadership-card',
    LANDING: 'landing-card',
    STAFF: 'staff-card',
    PLACEHOLDER_ICON: 'card-placeholder-icon',
    EMPHASIZED: 'emphasized-card',
    EVENT: 'event-card',
    TITLE: 'card-title',
    SUBTITLE: 'subtitle-card',
    LINK: 'link-card',
    IDEA: 'idea-card',
    PROFILE: 'profile-card',
    OPINION: 'opinion-card',
    ARTICLE: 'article-card',
    GRANT: 'grant-card',
    HIGHLIGHT: 'highlight-card',
    NEWS: 'news-card',
    MMUF: 'mmuf-card',
    FEATURE: 'feature',
  },
  CAROUSEL: {
    GLANCE: 'carousel-at-glance-card',
  },
  HEADER: {
    TEXT: 'header-text',
    IMAGE: 'header-image',
    IMAGE_TITLE: 'header-image-title',
  },
  SEARCH: {
    INPUT: 'search-input-field',
    BUTTON: 'search-button',
    SUGGESTED_ITEM: 'search-suggested-entry',
    FEATURED: 'search-featured-entry',
    SUGGESTED_TITLE: 'search-suggested-title',
  },
  SPOTLIGHT: {
    WRAP: 'spotlight-wrap',
    VIDEO: 'spotlight-video',
    CONTENT: 'spotlight-content',
  },
  FOOTER: {
    WRAP: 'footer-wrap',
    IDEAS: 'footer-ideas',
    LINKS: 'footer-links',
    NEWSLETTER: 'footer-newsletter',
  },
  IMAGE: 'image',
  LINK: 'link',
  MODAL: 'modal',
  NAVIGATION: 'navigation',
  NEWSLETTER: 'newsletter',
  QUICK_LINKS: 'quick-links',
  QUOTE: 'quote',
  VIDEO: 'video',
  QUOTE_TWITTER: 'quote-twitter',
};
