import { type FilterUrlType } from '~/v1/components/filter/filter.interface';
import { encodeFilters } from '~/v1/components/filter/filter.utils';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { type LinkData } from '~/v1/components/quickLinks/quickLinks';
import { type Route } from '~/v1/constants/route';
import { isInternalLink } from '~/v1/utils/isInternalLink';

export type LinkType = 'internal' | 'external' | 'asset';

export type LinkHref =
  | string
  | {
      route: Route;
      slug?: string;
      filters?: Record<string, FilterUrlType[]>;
    };

export interface LinkProps {
  type: LinkType;
  href: LinkHref;
  label: string;
}

export function createLink({ href, label }: LinkProps): LinkData {
  return { label, link: getLinkHref(href) };
}

function getLinkHref(href: LinkHref) {
  if (typeof href === 'string') {
    return href;
  }
  const { route, slug, filters } = href;

  const path = slug ? `${route}/${slug}` : route;
  return filters ? encodeFilters(path, filters) : path;
}

export function getLinkIcon(href: string) {
  if (href.endsWith('.pdf')) {
    return IconType.Pdf;
  }
  if (isInternalLink(href)) {
    return IconType.ExpandingArrowRight;
  }
  return IconType.ExpandingArrowDiagonal;
}
