import {
  type Provider,
  createContext as createContextReact,
  useContext as useReactContext,
} from 'react';

// Create context without worrying about undefined values
// https://www.carlrippon.com/react-context-with-typescript-p4/
/** @deprecated use React.createContext instead */
export function createContext<ContextType>(): {
  Provider: Provider<ContextType | undefined>;
  useContext: () => ContextType;
} {
  const context = createContextReact<ContextType | undefined>(undefined);
  function useContext() {
    const contextValue = useReactContext(context);

    if (contextValue === undefined) {
      throw new Error('useContext must be inside a Provider with a value');
    }
    return contextValue;
  }
  return { Provider: context.Provider, useContext };
}
